import React from "react";
import { useLocation } from "@reach/router";
import { FaFacebookF } from "@react-icons/all-files/fa/FaFacebookF";
import { FaTwitter } from "@react-icons/all-files/fa/FaTwitter";
import { Link } from "gatsby";
import Logo2 from "../../assets/images/dm-Logo.png";

import "../../styles/components/Layout/footer.scss";

const stripSlashes = (path) => {
  return path.split("/").join("");
};

function Footer() {
  const location = useLocation();

  React.useEffect(() => {
    const currentPath = stripSlashes(location.pathname);
    if (currentPath === "teachers-schools") {
      document
        .getElementById("teachersFLink")
        .firstChild.classList.toggle("active");
    }
    if (currentPath === "students") {
      document
        .getElementById("studentsFLink")
        .firstChild.classList.toggle("active");
    }
    if (currentPath === "about") {
      document
        .getElementById("aboutFLink")
        .firstChild.classList.toggle("active");
    }
    if (currentPath === "faqs") {
      document
        .getElementById("faqsFLink")
        .firstChild.classList.toggle("active");
    }
    if (currentPath === "contact") {
      document
        .getElementById("contactFLink")
        .firstChild.classList.toggle("active");
    } 
    if (currentPath === "jobs") {
      document
        .getElementById("jobsFLink")
        .firstChild.classList.toggle("active");
    }
  }, [location.pathnmame]);

  return (
    <div className="footer__container">
      <div className="footer__logo">
        <Link to="/">
          <img
            className="footer__logo-img"
            src={Logo2}
            alt="DeltaMath Footer logo"
          />
        </Link>
      </div>
      <div className="footer__navbar">
        <ul className="footer__nav-links">
          <li className="footer__nav-link" id="teachersFLink">
            <Link to="/teachers-schools">Teachers&#x200a;/&#x200a;Schools</Link>
          </li>
          <li className="footer__nav-link" id="studentsFLink">
            <Link to="/students">Students</Link>
          </li>
          <li className="footer__nav-link" id="aboutFLink">
            <Link to="/about">About</Link>
          </li>
          <li className="footer__nav-link" id="faqsFLink">
            <Link to="/faqs">FAQs</Link>
          </li>
          <li className="footer__nav-link" id="jobsFLink">
            <Link to="/jobs">Jobs</Link>
          </li>
          <li className="footer__nav-link" id="contactFLink">
            <Link to="/contact">Contact</Link>
          </li>
        </ul>
      </div>
      <div className="footer__bottom">
        <div className="footer__bottom-copyright">
          <span>
            &#169; {new Date().getFullYear()} DeltaMath Solutions Inc.
          </span>
          <Link to="/terms-of-service">Terms of Service</Link>
          <a href="https://help.deltamath.com/accessibility" target="_blank" rel="noopener noreferrer" >Accessibility</a>
          <a href="https://help.deltamath.com" target="_blank" rel="noopener noreferrer" >Help Center</a>
          <Link to="/privacy-policy">Privacy Policy</Link>
          <a href="https://status.deltamath.com">Site Status</a>
        </div>
        <div className="footer__bottom-social">
          <a
            href="https://www.facebook.com/DeltaMathWebsite/"
            id="facebook-social"
          >
            <span className="sr-only">Facebook</span>
            <FaFacebookF />
          </a>
          <a href="https://twitter.com/MrDeltaMath" id="twitter-social">
            <span className="sr-only">Twitter</span>
            <FaTwitter />
          </a>
        </div>
      </div>
    </div>
  );
}

export default Footer;
