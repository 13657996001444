import fetch from "node-fetch";

const getDeltaMathAPI = () => `${process.env.GATSBY_API_URL}`;

const actuallyLogin = (data, setError, clearErrors) => {
  const { redirectUrl, googleSSOTimestamp } = data;
  if (setError && !data.user) {
    setError("submit", {
      type: "manual",
      message: "There was an error logging in.",
    });
  }

  if (clearErrors) clearErrors("submit");

  if (typeof window !== "undefined") {
    localStorage.setItem("id_token", data.jwt);
    localStorage.setItem("user_to_login", JSON.stringify(data));
    if (data.customer_service_token) {
      localStorage.setItem(
        "customer_service_token",
        data.customer_service_token
      );
    }
    if (data.admin) {
      localStorage.setItem("admin", JSON.stringify(data.admin));
    }
    localStorage.removeItem("lti_auth_data");
    localStorage.removeItem("lti_assignment_payload");
    localStorage.removeItem("lti_data_meta");
    localStorage.removeItem("impersonateTeacher");
    localStorage.removeItem("impersonateStudent");
  }

  let url = "";

  if (
    (redirectUrl && data.isTeacher === 0 && redirectUrl.includes("student")) || // Student OK
    (redirectUrl && data.isTeacher === 1 && redirectUrl.includes("teacher")) || // Teacher OK
    (redirectUrl && data.isTeacher === 1 && data.admin && (redirectUrl.includes("admin") || redirectUrl.includes("teacher"))) // Admin OK
  ) {
    url = redirectUrl;
  } else if (
    data.user &&
    data.user.salutation &&
    !(
      data.admin &&
      data.admin.account_type &&
      data.user.defaultView === "admin"
    )
  ) {
    // teacher
    if (data.user.course_activation_code) url = "teacher/data";
    else url = "teacher";
  } else if (data.account_type || (data.admin && data.admin.account_type)) {
    // admin
    let route =
      data.account_type === "district" ||
      data.account_type === "super_district" ||
      data.admin.account_type === "district" ||
      data.admin.account_type === "super_district"
        ? "district/current-admins"
        : "schools";
    // non-teacher admins who have not sso'd in should go to profile if they haven't updated their passwords
    if (
      !googleSSOTimestamp &&
      data.admin &&
      !data.admin.isTeacherAdmin &&
      !data.admin.lastPasswordReset
    )
      route = "profile";
    url = `admin/${route}`;
  } else {
    url = "student";
  }
  let hostname;
  let prefix = "https://";
  let href;
  if (typeof window !== "undefined") {
    hostname = window.location.hostname;
    if (hostname === "localhost") {
      hostname += ":4200";
      prefix = "http://";
      href = prefix + hostname + "/" + url;
    } else {
      href = prefix + hostname + "/app/" + url;
    }
  }
  window.location.href = href;
};

/**
 * Evaluates whether or not a password string meets SOC2 security standards.
 * @param {*} str password string to evaluate
 * @returns true or false, depending on if the string fulfills > 3/4 of the security requirements
 */
export const evaluatePassword = (str) => {
  const hasUppercase = str.match(/[A-Z]/) !== null;
  const hasLowercase = str.match(/[a-z]/) !== null;
  const hasSpecialChar = str.match(/[^a-zA-Z0-9]/) !== null;
  const hasNumber = str.match(/\d/) !== null;

  const requirementsMet =
    hasUppercase + hasLowercase + hasSpecialChar + hasNumber;

  return str.length >= 10 && requirementsMet >= 3;
};

const processPreloginFactor = (num) => {
  let upper = Math.sqrt(num);
  for (var i = 2; i < upper; i++) {
    if (num % i === 0) {
      return i;
    }
  }
  return num;
};

// action param allows validation data to be grouped in the google recaptcha admin portal
// and can be used by the backend to conditionally handle use cases based on action value
const getRecaptchaToken = async (action) => {
  // note - action name must be A-Za-z/_, no spaces or other symbols
  if (typeof window !== "undefined" && window.grecaptcha) {
    try {
      const token = await window.grecaptcha.execute(
        process.env.GATSBY_GOOGLE_RECAPTCHA_KEY,
        { action }
      );
      return token;
    } catch (err) {
      return null;
    }
  } else {
    return null;
  }
};

// to enable recaptcha in a view/component:
// 1) call #loadRecaptchaScript from inside React.useEffect(() => {...}, []);
// 2) add element to html: <div className="g-recaptcha" data-sitekey={process.env.GATSBY_GOOGLE_RECAPTCHA_KEY}></div>
// 3) call #getRecaptchaToken before backend call that we want to protect with recaptcha
// 4) on backend, verify the recaptcha token and handle the response however we want

const loadRecaptchaScript = function () {
  const script = document.createElement("script");
  script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.GATSBY_GOOGLE_RECAPTCHA_KEY}`;
  document.body.appendChild(script);
};

const handlePrelogin = async (email) => {
  let requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      email: email,
    }),
  };

  let response = await fetch(getDeltaMathAPI() + "/prelogin", requestOptions);
  let responseJSON;
  if (response) responseJSON = await response.json();
  else throw response;
  return responseJSON;
};

const sendSlackLog = async (title, text, context, level = "error") => {
  const parsedText = typeof text !== "string" ? JSON.stringify(text) : text;
  const payload = {
    title,
    level,
    text: parsedText,
    context,
  };
  const options = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(payload),
  };
  fetch(getDeltaMathAPI() + "/slack_logger", options);
};

export {
  actuallyLogin,
  processPreloginFactor,
  handlePrelogin,
  getRecaptchaToken,
  loadRecaptchaScript,
  sendSlackLog,
  getDeltaMathAPI,
};
