import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Helmet } from "react-helmet";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { useToggle } from "@react-md/utils";
import "../../styles/layout.scss";

const useSiteMetadata = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
        }
      }
    }
  `);
  return data.site.siteMetadata;
};

function Layout(props) {
  const { children, signIn, redirectUrl, hideFooter, isEmbedded } = props;
  const { title, description } = useSiteMetadata();
  const [showOverlay, enableOverlay, disableOverlay] = useToggle(false);
  const handleOverlayClick = () => {
    disableOverlay();
  };
  return (
    <>
      <Helmet
        bodyAttributes={{
          class: "body-full-width",
        }}
      >
        <html lang="en" />
        <title>{title}</title>
        <meta name="description" content={description} />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#273e4f" />
        <meta name="msapplication-TileColor" content="#2b5797" />
        <meta name="theme-color" content="#ffffff" />
      </Helmet>
      {!isEmbedded && (
        <header>
          <Navbar
            signIn={signIn}
            redirectUrl={redirectUrl}
            enableOverlay={enableOverlay}
            disableOverlay={disableOverlay}
          />
        </header>
      )}
      <main className={isEmbedded ? 'is-embedded': ''}>
        {children}
        {showOverlay ? (
          <div
            onClick={handleOverlayClick}
            style={{
              height: "100%",
              width: "100%",
              backgroundColor: "black",
              opacity: 0.6,
              zIndex: 1000,
              position: "fixed",
              top: 0,
              overflow: "hidden",
            }}
          ></div>
        ) : (
          <></>
        )}
      </main>
      {!isEmbedded && <footer>{!hideFooter ? <Footer /> : <></>}</footer>}
    </>
  );
}

export default Layout;
